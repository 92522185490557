/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_dynamodb_all_tables_region": "us-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "gps-points-db-dev",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:98fde069-d43d-4bd0-9114-e3c98ed415d8",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_JJw6FSpB7",
    "aws_user_pools_web_client_id": "4qao8bmp1hsecf601qsr6kiql4",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "uiapi",
            "endpoint": "https://d2skabgkq9.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "gpsrecord",
            "endpoint": "https://kinjb3ddu2.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ]
};


export default awsmobile;

import * as React from 'react';
import ReactMapGL from 'react-map-gl';
import MAP_STYLE from './map-style-basic-v8.json';
import { DateTime } from "luxon";

function makeFeature(p) {
    const now = DateTime.now();
    const dt = DateTime.fromSeconds(p.utc_timestamp);

    let color = '#4264fb';

    if ((now.diff(dt).toMillis() / 1000) < (3600 * 2)) {
        color = "#fb1222"
    } else if ((now.diff(dt).toMillis() / 1000) < (3600 * 24)) {
        color = "#b6228a"
    }

    if (p.lat != 0) {

        return {
            'type': 'Feature',
            'geometry': {
                'type': 'Point',
                'coordinates': [
                    p.lon, p.lat
                ]
            },
            'properties': {
                'color': color,
                'radius': 3,
                'opacity': 1,
                'id': p.id,
            }
        }
    } else {
        if (p.hotspots === undefined) {
            return
        }

        // fetch coordinates from hotspot
        let lat = p.hotspots[0].lat
        let lon = p.hotspots[0].long

        return {
            'type': 'Feature',
            'geometry': {
                'type': 'Point',
                'coordinates': [
                    lon, lat
                ]
            },
            'properties': {
                'color': color,
                'radius': 14,
                'opacity': 0.3,
                'id': p.id
            }
        }
    }
}

export function Map(props) {
    const [viewport, setViewport] = React.useState({
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 8
    });

    let point_index = {}
    for (const p of props.points) {
        point_index[p.id] = p
    }

    let line_coordinates = [[]];

    let filtered_points = [];
    if (props.useHotspotLocations) {
        filtered_points = props.points.filter((x) => (x.lat != 0 || (x.hotspots && x.hotspots.length)))
    } else {
        filtered_points = props.points.filter((x) => (x.lat != 0 ))
    }

    let point_features = filtered_points.map(
        (p) => {
            const feat = makeFeature(p)
            line_coordinates[0].push(feat.geometry.coordinates)
            return feat
        }
    )

    let map_style = {
        ...MAP_STYLE,
        sources: { ...MAP_STYLE.sources },
        layers: MAP_STYLE.layers.slice()
    }

    map_style.sources['points'] = {
        "type": "geojson",
        'data': {
            'type': 'FeatureCollection',
            'features': point_features
        }
    };

    const line_features = line_coordinates.map(
        (coords) => {
            return {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'LineString',
                    'coordinates': coords
                }
            }
        }
    )

    map_style.sources['lines'] = {
        "type": "geojson",
        'data': {
            'type': 'FeatureCollection',
            'features': line_features
        }
    };

    if (props.renderStyle == "lines") {
        map_style.layers.push({
            'id': 'lines',
            'type': 'line',
            'source': 'lines',
            'layout': {
                'line-join': 'round',
                'line-cap': 'round'
            },
            'paint': {
                'line-color': '#888',
                'line-width': 2
            }
        })
    }


    map_style.layers.push({
        'id': 'points',
        'type': 'circle',
        'source': 'points',
        'paint': {
            'circle-color': ["get", "color"],
            'circle-radius': ["get", "radius"],
            'circle-opacity': ["get", "opacity"],
            'circle-stroke-width': 2,
            'circle-stroke-color': '#ffffff'
        },
        /*
    'layout': {
        // get the title name from the source's "title" property
        //'text-field': ['get', 'title'],
        'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
        ],
        'text-offset': [0, 1.25],
        'text-anchor': 'top'
    }*/
    });

    if (props.selected != null) {
        console.log(props.selected, props.selected.utc_timestamp)

        map_style.sources['selected'] = {
            "type": "geojson",
            'data': {
                'type': 'FeatureCollection',
                'features': [
                    makeFeature(props.selected)
                ]
            }
        };

        map_style.layers.push({
            'id': 'selected',
            'type': 'circle',
            'source': 'selected',
            'paint': {
                'circle-color': '#faf',
                'circle-radius': ["get", "radius"],
                'circle-opacity': 1, //["get", "opacity"],
                'circle-stroke-width': 3,
                'circle-stroke-color': '#ffff00'
            },
        });
    }

    return (
        <ReactMapGL
            {...viewport}
            mapboxApiAccessToken='pk.eyJ1Ijoib2F2ZGVldiIsImEiOiJja2pjMHV1b2MzM2RqMnRucGg2Y2x6dDRvIn0.xJYSRWil0gDZy0SehrnIYw'
            width="100%"
            height="100%"
            mapStyle={map_style}
            onViewportChange={(viewport) => setViewport(viewport)}
            onClick={(pe) => {
                for (const p of pe.features) {
                    if (p.source == "points") {
                        console.log(p.properties.id)
                        props.onSelect(point_index[p.properties.id]);
                        return
                    }
                }
                props.onSelect(null)
            }
            }
        />
    );
}
import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { DateTime } from "luxon";

function fmt_hmdiff(hmdiff) {
    if (hmdiff.hours == 0) {
        return Math.round(hmdiff.minutes) + 'm'
    } else {
        return hmdiff.hours + 'h' + Math.round(hmdiff.minutes) + 'm'
    }
}
export class DataTableBasicDemo extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let ts_formatter = (x) => {
            const dt = DateTime.fromSeconds(x.utc_timestamp)
            const now = DateTime.now()

            if (now.diff(dt, 'hours').toObject().hours < 24) {
                const hmdiff = now.diff(dt, ['hours', 'minutes'])

                return dt.toFormat('HH:mm:ss') + ' (' + fmt_hmdiff(hmdiff) + ' ago)'
            } else {
                return dt.toFormat('yyyy-MM-dd HH:mm:ss')
            }
        };
        let bat_percentage = (x) => {
            if (x > 4000) {
                return "~100%"
            } else if (x >= 3850) {
                return "60-80%"
            } else if (x >= 3700) {
                return "40-60%"
            } else if (x >= 3400) {
                return "20-40%"
            } else {
                return "<20%"
            }
        }
        let bat_formatter = (x) => {
            return bat_percentage(x.battery) + " (" + x.battery + "mV)"
        }
        return (
            <div>
                <div className="card">
                    <DataTable value={this.props.points}
                            sortField={"utc_timestamp"}
                            sortOrder={-1}
                            selectionMode="single"
                            onSelectionChange={e => this.props.onSelectionChange(e.value)}
                            selection={this.props.selected}>
                        <Column field="utc_timestamp" header="Timestamp" body={ts_formatter}></Column>
                        <Column field="lat" header="Lat"></Column>
                        <Column field="lon" header="Lon"></Column>
                        <Column field="battery" header="Battery" body={bat_formatter}></Column>
                        <Column field="alarm" header="Alarm"></Column>
                    </DataTable>
                </div>
            </div>
        );
    }
}
import logo from './logo.svg';
import { Calendar } from 'primereact/calendar';

import { SelectButton } from 'primereact/selectbutton';
import { Checkbox } from 'primereact/checkbox';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import React, { Component } from 'react';
import { DataTableBasicDemo } from './DataTable.js';
import { Map } from './Map.js';
import 'primeflex/primeflex.css';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import Amplify, { API, sectionFooterSecondaryContent } from 'aws-amplify';
import { DateTime, Duration } from "luxon";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      date_range: [
        DateTime.now().set({hour: 0, minute: 0, second: 0}).toJSDate(),
        DateTime.now().set({hour: 0, minute: 0, second: 0}).toJSDate()
      ],
      points: [],
      selected: null,
      render_style: "points",
      useHotspotLocations: true };
  }

  refresh(date_range) {
    let params = {'tracker_id': 'LGT-02'}

    console.log(date_range)
    if (date_range != null) {
      params['startTs'] = (date_range[0] != null?(DateTime.fromJSDate(date_range[0]).toSeconds()):'')
      params['endTs'] = (date_range[1] != null?(DateTime.fromJSDate(date_range[1]).toSeconds() + 86400):'')
    }

    API
      .get("uiapi", "/data", {queryStringParameters: params})
      .then(response => {
        console.log(response)
        this.setState({ points: response })
      })
      .catch(error => {
        console.log("yoyo error")
        console.log(error);
      });
  }

  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      15000
    );

    console.log("YO")
    this.refresh(this.state.date_range)
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.refresh(this.state.date_range)
  }

  render() {
    const renderStyleOptions = [
      {label: 'Lines', value: 'lines'},
      {label: 'Points', value: 'points'},
  ];
    return (
      <div className="App">
        <header className="App-header">
        <div className="p-grid p-grid-responsive">
        <div className="p-field p-col-12 p-lg-4" >
          <label htmlFor="dateRange" style={{width:'100px'}}>Date Range: </label>
          <Calendar
          inputStyle={{'width':'14em'}}
          showIcon={true} showButtonBar
          id="dateRange"
          selectionMode="range"
          value={this.state.date_range}
          onChange={(e) => { 
            this.setState({date_range: e.value});
            this.refresh(e.value);
          }}></Calendar>
        </div>
        <div className="p-field p-col-12 p-lg-2">
          <SelectButton id="render_style" options={renderStyleOptions} value={this.state.render_style} onChange={e => this.setState({render_style: e.value})}></SelectButton>
        </div>
        <div className="p-field-checkbox p-col-12 p-lg-3">
          <Checkbox id="use_hotspot_locations" checked={this.state.useHotspotLocations} onChange={e => this.setState({useHotspotLocations: e.checked})}></Checkbox>
          <label htmlFor="use_hotspot_locations" >Fall back to hotspot locations</label>
        </div>
        </div>
         <div className="p-d-flex" style={{height: '500px'}}>
            <Map 
            points={this.state.points}
            selected={this.state.selected}
            renderStyle={this.state.render_style}
            useHotspotLocations={this.state.useHotspotLocations}
            onSelect={(x) => {this.setState({selected: x})}}
            />
          </div>
          <div className="p-d-flex">
            <DataTableBasicDemo points={this.state.points} selected={this.state.selected} onSelectionChange={(x) => {this.setState({selected: x}); }}/>
          </div>
          <p>
            Edit <code>src/App.js</code> and save to reload!!!.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
      </div>
    );
  }
}


export default withAuthenticator(App);
